// == Global settings

@import '../../../../vendor/metronic/theme/sass/global/_variables';
@import '../../../../vendor/metronic/theme/sass/global/components/_mixins';

// == General CSS Reset

// @if $theme-style == "square" {
    @import '../../../../vendor/metronic/theme/sass/global/components/_reset-rounds';
// }

// == General Components

@import '../../../../vendor/metronic/theme/sass/global/components/_typography';
@import '../../../../vendor/metronic/theme/sass/global/components/_utils';
@import '../../../../vendor/metronic/theme/sass/global/components/_font-icons'; 
@import '../../../../vendor/metronic/theme/sass/global/components/_alerts'; 
@import '../../../../vendor/metronic/theme/sass/global/components/_buttons';
@import '../../../../vendor/metronic/theme/sass/global/components/_form-layouts';  
@import '../../../../vendor/metronic/theme/sass/global/components/_inputs';
@import '../../../../vendor/metronic/theme/sass/global/components/_icon-buttons';
@import '../../../../vendor/metronic/theme/sass/global/components/_input-icons';

// == Apply flat UI colors to all components

@import '../../../../vendor/metronic/theme/sass/global/components/_colors';

// == Login Styles

@import '../../../../vendor/metronic/theme/sass/pages/login.scss';